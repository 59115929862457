// Flyers
// Add office, hospital, nursing home, home to cont of care.
import * as React from 'react';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import { Helmet } from "react-helmet";
import { Link, useStaticQuery, graphql } from 'gatsby';
import sal from 'sal.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import BookButton from '../components/BookButton';

function Index() {
  React.useEffect(() => {
    sal();
  }, []);

  const scrollDiv = React.useRef(null);

  const query = useStaticQuery(graphql`
  query { allMarkdownRemark(filter: {frontmatter: {author: {ne: null}}}) {
          edges {
            node {
              id
              html
              frontmatter {
                author
                lead_image
                published
                slug
                title
              }
            }
          }
        }
      }
    `);

  let frontmatter = [];
  query.allMarkdownRemark.edges.forEach(post => {
    frontmatter.push(post);
  });

  const metaDesc = `New Beginnings provides Suboxone maintenance treatment to Portage and surrounding Northwest Indiana communities.`

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        ( <title>{`New Beginnings Suboxone Clinic - Addiction and substance abuse doctors in Portage, Indiana.`}</title> )
        <meta name="Description" content={metaDesc} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link href="https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Mali:wght@600&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-X7TPGZBWQC"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-X7TPGZBWQC');
          `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-204995244-2"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-204995244-2');
          `}
        </script>
      </Helmet>

      <Nav menuItem="index" />

      <section className="flex flex-col-reverse md:flex-row h-full md:h-screen w-full">
        <div className="w-full m-auto">
          <div className="w-full text-center">
            <h1 data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="1500"
              data-sal-easing="ease-out-quint" className="mb-8 mt-8 md:mt-0 text-2xl font-semibold leading-none tracking-tighter text-black sm:text-5xl title-font">
              Welcome to New Beginnings!
            </h1>
          </div>

          <div className="w-9/10 px-10">
            <p data-sal="slide-right"
              data-sal-delay="300"
              data-sal-duration="1500"
              data-sal-easing="ease-out-quint" className="text-lg leading-none sm:text-lg">
              New Beginnings saves lives with medication-assisted treatment for opioid and alcohol dependence. Our physicians properly prescribe FDA-approved medications for alcohol addiction including buprenorphine (Suboxone, Subutex).
            </p>
            <p data-sal="slide-right"
              data-sal-delay="400"
              data-sal-duration="1500"
              data-sal-easing="ease-out-quint" className="mt-4 text-lg leading-none sm:text-lg">
              New Beginnings serves patients in Portage, Indiana as well as surrounding communities here in Northwest Indiana.
            </p>
          </div>


          <div data-sal="slide-right"
            data-sal-delay="500"
            data-sal-duration="1500"
            data-sal-easing="ease-out-quint" className="flex mt-8 max-w-9/10 mx-auto overflow-hidden bg-white rounded-lg border-r-4 border-indigo-200  shadow-sm">
            <div className="flex rounded-r w-full h-full">
              <div className="w-1/4 bg-cover" style={{ backgroundImage: 'url(../addiction.jpeg)', backgroundPosition: 'center' }}></div>

              <div className="w-3/4 m-4">
                <h1 className="text-2xl font-bold text-gray-700">Substance Abuse Treatment</h1>

                <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">We provide Suboxone maintenance treatment along with counseling to those struggling with addiction. Book an appointment with us today and help get your life back on track.</p>
                <div className="flex justify-between mt-3 float-right item-center">
                  <Link to="/services/suboxone-clinic" className="inline-flex px-2 py-1 text-xs font-medium text-indigo-500 uppercase transition-colors duration-200 transform bg-indigo-100 rounded hover:bg-indigo-200 focus:outline-none focus:bg-gray-700">Learn More <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24"> <path d="M5 12h14M12 5l7 7-7 7"></path> </svg></Link>
                </div>
              </div>
            </div>
          </div>

          <div data-sal="slide-up"
            data-sal-delay="400"
            data-sal-duration="2000"
            data-sal-easing="ease-out-quint" className="text-center flex flex-row items-center justify-center mt-12">
            <Link to="/services/suboxone-clinic" className="inline-flex items-center justify-center h-12 px-6 mr-4 shadow font-medium tracking-wide text-gray-500 transition duration-200 rounded bg-gray-100 hover:bg-gray-200 focus:shadow-outline focus:outline-none"><FontAwesomeIcon icon={faInfo} size="md" className="mr-2" /> <span className="mt-1">Learn More</span></Link>
            <BookButton nav="true" />
          </div>
        </div>

        <div className="w-full md:block">
          <div className="flex justify-center justify-items-center w-full h-96 md:h-full mb-10 lg:mb-0">
            <img data-sal="slide-left"
              data-sal-delay="350"
              data-sal-duration="1500"
              data-sal-easing="ease-out-quint" className=" object-cover object-center md:object-bottom w-full h-96 md:h-full" src="../happy-fam8.jpeg" alt="glasses photo" />
              <div className="absolute w-5/12 text-gray-700 font-handwritten font-regular text-4xl bg-white bg-opacity-60 p-10 rounded-xl mt-20">
                <p><strong>Just for today</strong>,
                I will try to live through this day only.</p>
                <p className="mt-4">I can do something for 12 hours that would appall me if I felt that I had to keep it up for a lifetime.</p>
              </div>
          </div>
        </div>
      </section>

      {/* <section className="flex flex-col h-screen lg:h-screen sm:h-full w-full my-0 via-blue-700 to-indigo-800 text-white" style={{ backgroundImage: 'url(../fall4.jpeg)', backgroundPosition: 'top', backgroundSize: 'cover' }}>
        <div className="flex flex-wrap content-center container sm:h-full px-12 lg:px-0 m-auto max-w-xl md:max-w-full lg:max-w-screen-xl">
          <div className="grid gap-5 row-gap-8 lg:grid-cols-7">
            <div className="col-span-2 order-1 lg:order-1 m-auto flex flex-row">
              <img data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="2000"
                data-sal-easing="ease-out-quint" className="object-contain h-full w-full" src="./continuity5.png" alt="" />
            </div>
            <div className="col-span-3 order-3 lg:order-2 flex flex-col justify-center">
              <div className="max-w-full mb-6 mt-6 lg:mt-0">
                <h2 data-sal="slide-right"
                  data-sal-delay="300"
                  data-sal-duration="1500"
                  data-sal-easing="ease-out-quint" className="mt-8 font-sans text-4xl text-center font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                  Continuity of <span className="text-indigo-200">Care</span>
                </h2>
                <p data-sal="slide-right"
                  data-sal-delay="300"
                  data-sal-duration="1500"
                  data-sal-easing="ease-out-quint" className="text-base mt-8 text-white md:text-lg">
                  Being able to care for patients at all stages of life allows us to focus more individually on the patient. From prenatal and pediatrics to general/family and geriatrics.
                </p>
                <p data-sal="slide-right"
                  data-sal-delay="300"
                  data-sal-duration="1500"
                  data-sal-easing="ease-out-quint" className="text-base text-white md:text-lg mt-4">
                  Whether it's in our office, at the hospital, nursing home, or your own home, our doctors will be there.
                </p>
                <div data-sal="slide-up"
                  data-sal-delay="400"
                  data-sal-duration="2000"
                  data-sal-easing="ease-out-quint" className="text-center mt-8">
                  <Link to="/services" className="inline-flex items-center justify-center mt-4 w-50 h-12 px-4 font-medium tracking-wide text-indigo-500 transition duration-200 rounded bg-indigo-100 hover:bg-indigo-200 focus:shadow-outline focus:outline-none uppercase">View Our Services</Link>
                </div>
              </div>
            </div>
            <div className="col-span-2 order-2 lg:order-3 m-auto">

              <img data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="2000"
                data-sal-easing="ease-out-quint" className="object-contain h-full w-full" src="./continuity6.png" alt="" />
            </div>
          </div>
        </div>
      </section> */}

      <section className="flex flex-col h-full md:h-screen lg:h-screen sm:h-full w-full my-24 bg-white via-blue-700 to-indigo-800 text-gray-700" style={{ backgroundImage: 'url()', backgroundPosition: 'top', backgroundSize: 'cover' }}>
        <div className="flex flex-wrap content-center container sm:h-full px-12 lg:px-0 m-auto max-w-xl md:max-w-full lg:max-w-screen-xl">
          <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
            <div>
              <img data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="2000"
                data-sal-easing="ease-out-quint" className="object-cover w-full h-56 rounded sm:h-96 lg:pr-8" src="./stethoscope-heart.jpeg" alt="" />
            </div>
            <div className="flex flex-col justify-center">
              <div className="max-w-full mb-6">
                <h2 data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-duration="1500"
                  data-sal-easing="ease-out-quint" className="mb-6 font-sans text-3xl font-bold tracking-tight text-gray-700 sm:text-4xl sm:leading-none">
                  Local doctors you can trust.
                </h2>
                <p data-sal="slide-up"
                  data-sal-delay="350"
                  data-sal-duration="1500"
                  data-sal-easing="ease-out-quint" className="text-base text-gray-700 md:text-lg">
                  New Beginnings has been caring for Northwest Indiana for over 34 years and is dedicated to providing high quality healthcare in a compassionate, ethical, and caring environment.
                </p>
              </div>
              <div className="grid gap-5 row-gap-8 sm:grid-cols-2">
                <div data-sal="slide-left"
                  data-sal-delay="350"
                  data-sal-duration="1500"
                  data-sal-easing="ease-out-quint" className="bg-white border-l-4 shadow-sm border-indigo-400">
                  <div className="h-full p-5 rounded-r">
                    <h6 className="mb-2 font-semibold leading-5">
                      Suboxone Treatment
                    </h6>
                    <p className="text-sm text-gray-900">
                      Suboxone is a prescription medication in the form of a film or pill. It can be used for short-term or long-term detoxification or indefinitely as maintenance therapy.
                    </p>
                  </div>
                </div>
                <div data-sal="slide-left"
                  data-sal-delay="400"
                  data-sal-duration="1500"
                  data-sal-easing="ease-out-quint" className="bg-white border-l-4 shadow-sm border-indigo-300">
                  <div className="h-full p-5 rounded-r">
                    <h6 className="mb-2 font-semibold leading-5">
                      Serving our local community
                    </h6>
                    <p className="text-sm text-gray-900">
                      New Beginnings Suboxone Clinic is locally owned and run. We aren't a big company and provide individual attention to all of our patients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-col m-auto text-gray-700 h-full md:h-screen lg:h-screen sm:h-full my-24 sm:pt-12 md:mx-0 mx-8">
        <div className="mx-auto text-center"><h1 data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1500"
          data-sal-easing="ease-out-quint" className="mx-auto mb-4 text-2xl font-semibold tracking-tighter text-black sm:text-6xl title-font">
          What Our Patients Say
        </h1>
          <p data-sal="slide-up"
            data-sal-delay="300"
            data-sal-duration="1500"
            data-sal-easing="ease-out-quint" className="text-large">We have been serving the Northwest Indiana area for over 34 years, see what our patients have to say.</p>  </div>
        <div className="container grid grid-cols-12 gap-4 mx-auto">
          <div data-sal="slide-right"
            data-sal-delay="400"
            data-sal-duration="1500"
            data-sal-easing="ease-out-quint" className="my-auto md:col-span-2 col-span-12 md:col-start-2 mt-20 mx-auto text-center">
            <img src="../blonde-lady.jpeg" className="rounded-full object-cover w-44 h-44 md:mt-6 m-auto" />
          </div>
          <div data-sal="slide-left"
            data-sal-delay="400"
            data-sal-duration="1500"
            data-sal-easing="ease-out-quint" className="md:col-span-8 col-span-12 mt-10 md:mt-20 rounded-2xl bg-yellow-50 w-full h-full md:h-2/3 md:py-12 rounded-xl overflow-hidden" style={{ backgroundImage: "url('../quotation-mark3.png')", backgroundSize: '30%', backgroundRepeat: 'no-repeat', backgroundPosition: 'right' }}>

            <div className=" w-full px-5 mx-auto lg:px-24">

              {frontmatter.map(element => {
                return <div>
                  <div className="" dangerouslySetInnerHTML={{ __html: element.node.html }} />
                  <p className="text-sm float-right">- {element.node.frontmatter.author}</p>
                </div>
              })}
            </div>
          </div>
        </div>


      </section>


      {/* <section className="container flex flex-col-reverse md:flex-col mx-auto px-4 mb-24 md:mt-24 mx-auto space-y-6 lg:h-128 lg:py-24 lg:flex-row lg:items-center lg:space-x-6 mt-10">
        <div className="w-full lg:w-1/2 mt-10 lg:mt-0">
          <div className="lg:max-w-lg">
            <h1 data-sal="slide-right"
              data-sal-delay="300"
              data-sal-duration="1500"
              data-sal-easing="ease-out-quint" className="text-2xl font-bold tracking-wide text-gray-800 dark:text-white lg:text-4xl">Schedule your appointment today</h1>
            <p data-sal="slide-right"
              data-sal-delay="300"
              data-sal-duration="1500"
              data-sal-easing="ease-out-quint" className="mt-2 text-gray-600 dark:text-gray-300">Welcome to New Beginnings! Appointments can now be scheduled online to help speed up your first or next office visit!</p>
            <p data-sal="slide-right"
              data-sal-delay="300"
              data-sal-duration="1500"
              data-sal-easing="ease-out-quint" className="mt-2 text-gray-600 dark:text-gray-300">We accept Medicare, Medicaid, most insurance, and cash payments.</p>
            <div data-sal="slide-right"
              data-sal-delay="300"
              data-sal-duration="1500"
              data-sal-easing="ease-out-quint" className="grid gap-6 mt-8 sm:grid-cols-2">
              <div className="flex items-center space-x-6 text-gray-800 dark:text-gray-200">
                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>

                <span>Prenatal</span>
              </div>

              <div className="flex items-center space-x-6 text-gray-800 dark:text-gray-200">
                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>

                <span>Pediatrics</span>
              </div>

              <div className="flex items-center space-x-6 text-gray-800 dark:text-gray-200">
                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>

                <span>Primary Care</span>
              </div>

              <div className="flex items-center space-x-6 text-gray-800 dark:text-gray-200">
                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>

                <span>Geriatrics</span>
              </div>

              <div className="flex items-center space-x-6 text-gray-800 dark:text-gray-200">
                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>

                <span>Preventive Care</span>
              </div>

              <div className="flex items-center space-x-6 text-gray-800 dark:text-gray-200">
                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>

                <span>Substance Abuse</span>
              </div>
              <div className="col-span-2 text-center mt-6">
                <Link to="/services" className="inline-flex mb-4 items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-gray-700 transition duration-200 rounded bg-gray-100 hover:bg-gray-200 focus:shadow-outline focus:outline-none" aria-label="Book Appointment" title="Book Appointment"><FontAwesomeIcon icon={faInfo} size="md" className="mr-2" /> <span className="mt-1">Learn More</span></Link>
                <BookButton nav="true" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center justify-items-center w-full bg-blend-overlay bg-white opacity-90 h-full mb-10 lg:mb-0 lg:w-1/2">
          <img data-sal="slide-left"
            data-sal-delay="350"
            data-sal-duration="1500"
            data-sal-easing="ease-out-quint" className="object-cover object-center w-full h-96 max-w-2xl rounded-md shadow-lg" src="../happy-people5.jpeg" alt="glasses photo" />
        </div>
      </section> */}
      <Footer />
    </div>
  );
}

export default Index;


// The medical needs of elderly are diffferent because as bodies age, the need for maintaining health also changes. To remain functional and independent proper excercises and bringing balance in life is important. Preventive care including blood work, xrays, and vaccination are important for a long healthy life. 
// Cardiac health, cancer screening, nutrition, mobility, mental health, dementia, adult vaccination, fall prevention, 